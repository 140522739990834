/*!
 * ClockPicker v{package.version} (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 */
!function () {
    function a(a) {
        return document.createElementNS(j, a)
    }

    function b(a) {
        return (10 > a ? "0" : "") + a
    }

    function c(a) {
        var b = ++r + "";
        return a ? a + b : b
    }

    function d(d, h) {
        function j(a, b) {
            var c = m.offset(), d = /^touch/.test(a.type), e = c.left + s, g = c.top + s, j = (d ? a.originalEvent.touches[0] : a).pageX - e, l = (d ? a.originalEvent.touches[0] : a).pageY - g, n = Math.sqrt(j * j + l * l), q = !1;
            if (!b || !(t - v > n || n > t + v)) {
                a.preventDefault();
                var r = setTimeout(function () {
                    f.addClass("clockpicker-moving")
                }, 200);
                k && m.append(D.canvas), D.setHand(j, l, !b, !0), i.off(o).on(o, function (a) {
                    a.preventDefault();
                    var b = /^touch/.test(a.type), c = (b ? a.originalEvent.touches[0] : a).pageX - e, d = (b ? a.originalEvent.touches[0] : a).pageY - g;
                    (q || c !== j || d !== l) && (q = !0, D.setHand(c, d, !1, !0))
                }), i.off(p).on(p, function (a) {
                    i.off(p), a.preventDefault();
                    var c = /^touch/.test(a.type), d = (c ? a.originalEvent.changedTouches[0] : a).pageX - e, k = (c ? a.originalEvent.changedTouches[0] : a).pageY - g;
                    (b || q) && d === j && k === l && D.setHand(d, k), "hours" === D.currentView ? D.toggleView("minutes", x / 2) : h.autoclose && (D.minutesView.addClass("clockpicker-dial-out"), setTimeout(function () {
                        D.done()
                    }, x / 2)), m.prepend(L), clearTimeout(r), f.removeClass("clockpicker-moving"), i.off(o)
                })
            }
        }

        var l = g(y), m = l.find(".clockpicker-plate"), q = l.find(".clockpicker-hours"), r = l.find(".clockpicker-minutes"), z = l.find(".clockpicker-am-pm-block"), A = "INPUT" === d.prop("tagName"), B = A ? d : d.find("input"), C = d.find(".input-group-addon"), D = this;
        if (this.id = c("cp"), this.element = d, this.options = h, this.isAppended = !1, this.isShown = !1, this.currentView = "hours", this.isInput = A, this.input = B, this.addon = C, this.popover = l, this.plate = m, this.hoursView = q, this.minutesView = r, this.amPmBlock = z, this.spanHours = l.find(".clockpicker-span-hours"), this.spanMinutes = l.find(".clockpicker-span-minutes"), this.spanAmPm = l.find(".clockpicker-span-am-pm"), this.amOrPm = "PM", h.twelvehour) {
            var E = ['<div class="clockpicker-am-pm-block">', '<button type="button" class="btn btn-sm btn-default clockpicker-button clockpicker-am-button">', "AM</button>", '<button type="button" class="btn btn-sm btn-default clockpicker-button clockpicker-pm-button">', "PM</button>", "</div>"].join("");
            g(E);
            g('<button type="button" class="btn btn-sm btn-default clockpicker-button am-button">AM</button>').on("click", function () {
                D.amOrPm = "AM", g(".clockpicker-span-am-pm").empty().append("AM")
            }).appendTo(this.amPmBlock), g('<button type="button" class="btn btn-sm btn-default clockpicker-button pm-button">PM</button>').on("click", function () {
                D.amOrPm = "PM", g(".clockpicker-span-am-pm").empty().append("PM")
            }).appendTo(this.amPmBlock)
        }
        h.autoclose || g('<button type="button" class="btn btn-sm btn-default btn-block clockpicker-button">' + h.donetext + "</button>").click(g.proxy(this.done, this)).appendTo(l), "top" !== h.placement && "bottom" !== h.placement || "top" !== h.align && "bottom" !== h.align || (h.align = "left"), "left" !== h.placement && "right" !== h.placement || "left" !== h.align && "right" !== h.align || (h.align = "top"), l.addClass(h.placement), l.addClass("clockpicker-align-" + h.align), this.spanHours.click(g.proxy(this.toggleView, this, "hours")), this.spanMinutes.click(g.proxy(this.toggleView, this, "minutes")), B.on("focus.clockpicker click.clockpicker", g.proxy(this.show, this)), C.on("click.clockpicker", g.proxy(this.toggle, this));
        var F, G, H, I, J = g('<div class="clockpicker-tick"></div>');
        if (h.twelvehour)for (F = 1; 13 > F; F += 1)G = J.clone(), H = F / 6 * Math.PI, I = t, G.css("font-size", "120%"), G.css({
            "left": s + Math.sin(H) * I - v,
            "top": s - Math.cos(H) * I - v
        }), G.html(0 === F ? "00" : F), q.append(G), G.on(n, j); else for (F = 0; 24 > F; F += 1) {
            G = J.clone(), H = F / 6 * Math.PI;
            var K = F > 0 && 13 > F;
            I = K ? u : t, G.css({
                "left": s + Math.sin(H) * I - v,
                "top": s - Math.cos(H) * I - v
            }), K && G.css("font-size", "120%"), G.html(0 === F ? "00" : F), q.append(G), G.on(n, j)
        }
        for (F = 0; 60 > F; F += 5)G = J.clone(), H = F / 30 * Math.PI, G.css({
            "left": s + Math.sin(H) * t - v,
            "top": s - Math.cos(H) * t - v
        }), G.css("font-size", "120%"), G.html(b(F)), r.append(G), G.on(n, j);
        if (m.on(n, function (a) {
                0 === g(a.target).closest(".clockpicker-tick").length && j(a, !0)
            }), k) {
            var L = l.find(".clockpicker-canvas"), M = a("svg");
            M.setAttribute("class", "clockpicker-svg"), M.setAttribute("width", w), M.setAttribute("height", w);
            var N = a("g");
            N.setAttribute("transform", "translate(" + s + "," + s + ")");
            var O = a("circle");
            O.setAttribute("class", "clockpicker-canvas-bearing"), O.setAttribute("cx", 0), O.setAttribute("cy", 0), O.setAttribute("r", 2);
            var P = a("line");
            P.setAttribute("x1", 0), P.setAttribute("y1", 0);
            var Q = a("circle");
            Q.setAttribute("class", "clockpicker-canvas-bg"), Q.setAttribute("r", v);
            var R = a("circle");
            R.setAttribute("class", "clockpicker-canvas-fg"), R.setAttribute("r", 3.5), N.appendChild(P), N.appendChild(Q), N.appendChild(R), N.appendChild(O), M.appendChild(N), L.append(M), this.hand = P, this.bg = Q, this.fg = R, this.bearing = O, this.g = N, this.canvas = L
        }
        e(this.options.init)
    }

    function e(a) {
        a && "function" == typeof a && a()
    }

    var f, g = window.jQuery, h = g(window), i = g(document), j = "http://www.w3.org/2000/svg", k = "SVGAngle" in window && function () {
            var a, b = document.createElement("div");
            return b.innerHTML = "<svg/>", a = (b.firstChild && b.firstChild.namespaceURI) == j, b.innerHTML = "", a
        }(), l = function () {
        var a = document.createElement("div").style;
        return "transition" in a || "WebkitTransition" in a || "MozTransition" in a || "msTransition" in a || "OTransition" in a
    }(), m = "ontouchstart" in window, n = "mousedown" + (m ? " touchstart" : ""), o = "mousemove.clockpicker" + (m ? " touchmove.clockpicker" : ""), p = "mouseup.clockpicker" + (m ? " touchend.clockpicker" : ""), q = navigator.vibrate ? "vibrate" : navigator.webkitVibrate ? "webkitVibrate" : null, r = 0, s = 100, t = 80, u = 54, v = 13, w = 2 * s, x = l ? 350 : 1, y = ['<div class="popover clockpicker-popover">', '<div class="arrow"></div>', '<div class="popover-title">', '<span class="clockpicker-span-hours text-primary"></span>', " : ", '<span class="clockpicker-span-minutes"></span>', '<span class="clockpicker-span-am-pm"></span>', "</div>", '<div class="popover-content">', '<div class="clockpicker-plate">', '<div class="clockpicker-canvas"></div>', '<div class="clockpicker-dial clockpicker-hours"></div>', '<div class="clockpicker-dial clockpicker-minutes clockpicker-dial-out"></div>', "</div>", '<span class="clockpicker-am-pm-block">', "</span>", "</div>", "</div>"].join("");
    d.DEFAULTS = {
        "default": "",
        "fromnow": 0,
        "placement": "bottom",
        "align": "left",
        "donetext": "\u5b8c\u6210",
        "autoclose": !1,
        "twelvehour": !1,
        "vibrate": !0
    }, d.prototype.toggle = function () {
        this[this.isShown ? "hide" : "show"]()
    }, d.prototype.locate = function () {
        var a = this.element, b = this.popover, c = a.offset(), d = a.outerWidth(), e = a.outerHeight(), f = this.options.placement, g = this.options.align, h = {};
        switch (b.show(), f) {
            case"bottom":
                h.top = c.top + e;
                break;
            case"right":
                h.left = c.left + d;
                break;
            case"top":
                h.top = c.top - b.outerHeight();
                break;
            case"left":
                h.left = c.left - b.outerWidth()
        }
        switch (g) {
            case"left":
                h.left = c.left;
                break;
            case"right":
                h.left = c.left + d - b.outerWidth();
                break;
            case"top":
                h.top = c.top;
                break;
            case"bottom":
                h.top = c.top + e - b.outerHeight()
        }
        b.css(h)
    }, d.prototype.show = function (a) {
        if (!this.isShown) {
            e(this.options.beforeShow);
            var c = this;
            this.isAppended || (f = g(document.body).append(this.popover), h.on("resize.clockpicker" + this.id, function () {
                c.isShown && c.locate()
            }), this.isAppended = !0);
            var d = ((this.input.prop("value") || this.options["default"] || "") + "").split(":");
            if ("now" === d[0]) {
                var j = new Date(+new Date + this.options.fromnow);
                d = [j.getHours(), j.getMinutes()]
            }
            this.hours = +d[0] || 0, this.minutes = +d[1] || 0, this.spanHours.html(b(this.hours)), this.spanMinutes.html(b(this.minutes)), this.toggleView("hours"), this.locate(), this.isShown = !0, i.on("click.clockpicker." + this.id + " focusin.clockpicker." + this.id, function (a) {
                var b = g(a.target);
                0 === b.closest(c.popover).length && 0 === b.closest(c.addon).length && 0 === b.closest(c.input).length && c.hide()
            }), i.on("keyup.clockpicker." + this.id, function (a) {
                27 === a.keyCode && c.hide()
            }), e(this.options.afterShow)
        }
    }, d.prototype.hide = function () {
        e(this.options.beforeHide), this.isShown = !1, i.off("click.clockpicker." + this.id + " focusin.clockpicker." + this.id), i.off("keyup.clockpicker." + this.id), this.popover.hide(), e(this.options.afterHide)
    }, d.prototype.toggleView = function (a, b) {
        var c = !1;
        "minutes" === a && "visible" === g(this.hoursView).css("visibility") && (e(this.options.beforeHourSelect), c = !0);
        var d = "hours" === a, f = d ? this.hoursView : this.minutesView, h = d ? this.minutesView : this.hoursView;
        this.currentView = a, this.spanHours.toggleClass("text-primary", d), this.spanMinutes.toggleClass("text-primary", !d), h.addClass("clockpicker-dial-out"), f.css("visibility", "visible").removeClass("clockpicker-dial-out"), this.resetClock(b), clearTimeout(this.toggleViewTimer), this.toggleViewTimer = setTimeout(function () {
            h.css("visibility", "hidden")
        }, x), c && e(this.options.afterHourSelect)
    }, d.prototype.resetClock = function (a) {
        var b = this.currentView, c = this[b], d = "hours" === b, e = Math.PI / (d ? 6 : 30), f = c * e, g = d && c > 0 && 13 > c ? u : t, h = Math.sin(f) * g, i = -Math.cos(f) * g, j = this;
        k && a ? (j.canvas.addClass("clockpicker-canvas-out"), setTimeout(function () {
            j.canvas.removeClass("clockpicker-canvas-out"), j.setHand(h, i)
        }, a)) : this.setHand(h, i)
    }, d.prototype.setHand = function (a, c, d, e) {
        var f, h = Math.atan2(a, -c), i = "hours" === this.currentView, j = Math.PI / (i || d ? 6 : 30), l = Math.sqrt(a * a + c * c), m = this.options, n = i && (t + u) / 2 > l, o = n ? u : t;
        if (m.twelvehour && (o = t), 0 > h && (h = 2 * Math.PI + h), f = Math.round(h / j), h = f * j, m.twelvehour ? i ? 0 === f && (f = 12) : (d && (f *= 5), 60 === f && (f = 0)) : i ? (12 === f && (f = 0), f = n ? 0 === f ? 12 : f : 0 === f ? 0 : f + 12) : (d && (f *= 5), 60 === f && (f = 0)), this[this.currentView] !== f && q && this.options.vibrate && (this.vibrateTimer || (navigator[q](10), this.vibrateTimer = setTimeout(g.proxy(function () {
                this.vibrateTimer = null
            }, this), 100))), this[this.currentView] = f, this[i ? "spanHours" : "spanMinutes"].html(b(f)), !k)return void this[i ? "hoursView" : "minutesView"].find(".clockpicker-tick").each(function () {
            var a = g(this);
            a.toggleClass("active", f === +a.html())
        });
        e || !i && f % 5 ? (this.g.insertBefore(this.hand, this.bearing), this.g.insertBefore(this.bg, this.fg), this.bg.setAttribute("class", "clockpicker-canvas-bg clockpicker-canvas-bg-trans")) : (this.g.insertBefore(this.hand, this.bg), this.g.insertBefore(this.fg, this.bg), this.bg.setAttribute("class", "clockpicker-canvas-bg"));
        var p = Math.sin(h) * o, r = -Math.cos(h) * o;
        this.hand.setAttribute("x2", p), this.hand.setAttribute("y2", r), this.bg.setAttribute("cx", p), this.bg.setAttribute("cy", r), this.fg.setAttribute("cx", p), this.fg.setAttribute("cy", r)
    }, d.prototype.done = function () {
        e(this.options.beforeDone), this.hide();
        var a = this.input.prop("value"), c = b(this.hours) + ":" + b(this.minutes);
        this.options.twelvehour && (c += this.amOrPm), this.input.prop("value", c), c !== a && (this.input.triggerHandler("change"), this.isInput || this.element.trigger("change")), this.options.autoclose && this.input.trigger("blur"), e(this.options.afterDone)
    }, d.prototype.remove = function () {
        this.element.removeData("clockpicker"), this.input.off("focus.clockpicker click.clockpicker"), this.addon.off("click.clockpicker"), this.isShown && this.hide(), this.isAppended && (h.off("resize.clockpicker" + this.id), this.popover.remove())
    }, g.fn.clockpicker = function (a) {
        var b = Array.prototype.slice.call(arguments, 1);
        return this.each(function () {
            var c = g(this), e = c.data("clockpicker");
            if (e)"function" == typeof e[a] && e[a].apply(e, b); else {
                var f = g.extend({}, d.DEFAULTS, c.data(), "object" == typeof a && a);
                c.data("clockpicker", new d(c, f))
            }
        })
    }
}();